import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import firebase from 'firebase/app'

export const fetchUser = createAsyncThunk('user/fetchUser', async (id) => {
  const userRef = firebase.firestore().doc(`/users/${id ?? null}`)
  const res = await userRef.get()
  const userData = res.data()

  return userData ? JSON.stringify(userData) : null;
})

export const getBalance = createAsyncThunk('user/getBalance', async (setToZero, { getState }) => {
  if (setToZero) {
    return 0
  }
  const user = userState(getState()).user
  if (user?.consultant) {
    try {
      const res = await firebase.functions().httpsCallable("getConsultantBalance")({
        consultantID: user.userID,
        consultantLocale: user.locale
      })
      return res.data.walletBalance;
    } catch (error) {
      return { error };
    }
  } else {
    return null
  }
})

export const getProfilePic = createAsyncThunk('user/getProfilePic', async (_, { getState }) => {
  try {
    const user = userState(getState()).user
    const res = await firebase.app().storage().ref(`images/${user?.userID}.jpg`).getDownloadURL()
    return res;
  } catch (_) {} 
})

export const checkTeamMembership = createAsyncThunk('user/checkTeamMembership', async () => {
  const res = await firebase.functions().httpsCallable("checkTeamMembership")()
  return res.data;
})

export const executeLogout = createAsyncThunk('user/executeLogout', async (_, { getState }) => {
  const currentUser = firebase.auth().currentUser
  const userRef = firebase.app().firestore().doc(`/users/${currentUser.uid}`)
  const updateData = {
      vapidKey: null,
  }
  const user = userState(getState()).user
  const userData = (await userRef.get()).data()
  if (user?.consultant) {
      updateData.online = false
      updateData.onlineCache = userData?.online
      updateData.canCallOnWeb = false
  }
  await userRef.update(updateData)
  await firebase.app().auth().signOut()

  localStorage.setItem('loggedInWidget', false)

  return null;
})

const initialState = {
  user: null,
  isLoading: true,
  isTeamMaster: false,
  teamCode: null,
  isTeamMember: false,
  isLogout: false,
  errorGettingUser: false,
  balance: null,
  profilePic: null,

  isTeamPage: false,
  notSetUserOnline: false,
  isSignUpInBookingProcess: false,
  errorMessage: null,
  severity: 'error',
  verticalAnchor: 'top',
  integration: null,
  isIntegrationTeam: false,
  testIntegration: false,

  consultantID: null,
  consultantsList: []
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserState(state, action) {
      state.user = {
        ...state.user,
        ...action.payload
      }
    },
    setIsTeamPage(state, action) {
      state.isTeamPage = action.payload
    },
    setNotSetUserOnline(state, action) {
      state.notSetUserOnline = action.payload
    },
    setIsSignUpInBookingProcess(state, action) {
      state.isSignUpInBookingProcess = action.payload
    },
    setErrorDetails(state, action) {
      state.errorMessage = action.payload.message
      state.severity = action.payload.severity ?? 'error'
      state.verticalAnchor = action.payload.verticalAnchor ?? 'top'
    },
    setIntegration(state, action) {
      state.integration = action.payload
    },
    setIsIntegrationTeam(state, action) {
      state.isIntegrationTeam = action.payload
    },
    setTestIntegration(state, action) {
      state.testIntegration = action.payload
    },
    setConsultantID(state, action) {
      state.consultantID = action.payload
    },
    setConsultantsList(state, action) {
      state.consultantsList = action.payload
    },
  },
  
  extraReducers(builder) {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {        
        const userParse = JSON.parse(action.payload)
        state.user = userParse
        
        state.isLogout = false
        state.errorGettingUser = false
        state.isLoading = false
      })
      .addCase(fetchUser.rejected, (state) => {
        state.user = null

        state.errorGettingUser = true
        state.isLoading = false
      });
      
    builder.addCase(getBalance.fulfilled, (state, action) => {
        state.balance = action.payload
      })
      
    builder.addCase(getProfilePic.fulfilled, (state, action) => {
        state.profilePic = action.payload
      })
      
    builder.addCase(checkTeamMembership.fulfilled, (state, action) => {
        state.isTeamMaster = action.payload.isTeamMaster
        state.teamCode = action.payload.code
        state.isTeamMember = action.payload.isTeamMember
      })
      
    builder
      .addCase(executeLogout.pending, (state) => {
        state.isLoading = true
      })
      .addCase(executeLogout.fulfilled, (state) => {
        state.isLoading = false
        state.user = null
        state.notSetUserOnline = true
        state.isLogout = true
      })
      .addCase(executeLogout.rejected, (state) => {
        state.isLoading = false
      });
  }
})

export const {
  setUserState,
  setIsTeamPage,
  setNotSetUserOnline,
  setIsSignUpInBookingProcess,
  setErrorDetails,
  setIntegration,
  setIsIntegrationTeam,
  setTestIntegration,
  setConsultantID,
  setConsultantsList
} = userSlice.actions

export const userState= state => state.user

export default userSlice.reducer
