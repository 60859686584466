import React, {  } from 'react'
import 'firebase/functions'
import BookingStepper from './BookingStepper'
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { capitalize } from '@material-ui/core';
import useCookieTheme from '../../../App/useCookieTheme';

const BookingInfo = (props) => {
  const { isGift, withoutStepper} = props
  const { consultantTitle, appointmentName } = useCookieTheme()

  return (
    <div className='item'>
      {!withoutStepper && <BookingStepper />}
      <div className='astrologer_text heading margin_item'>
        Start by selecting {!isGift && 'your desired Date below, then select an available Time, followed by'} the&nbsp;
        {appointmentName ? capitalize(appointmentName) : 'Call'} Type, 
        then {appointmentName ? capitalize(appointmentName) : 'Call'} Length. Prices for each&nbsp;
        {appointmentName ? capitalize(appointmentName) : 'Call'} Length are available in the drop down menu.<br /><br />
        {!isGift && 
          <>
            <b>Times listed are in your timezone.</b> You can click the <InfoIcon className='info_icon' /> button from the upper right of the calendar to see it..<br /><br />
          </>
        }
        Go to the next step of the booking process by clicking&nbsp;“CONTINUE”.<br /><br />
        {!isGift &&
          <>
            <i>If you've been gifted {appointmentName ? `a ${appointmentName} ` : 'an appointment ' }
            with this {consultantTitle}, enter your gift code by clicking </i>
            &nbsp;“I HAVE A GIFT OR DISCOUNT CODE”
          </>
        }
      </div>
    </div>
  )
}

export default BookingInfo;
