import React, { useEffect, useState } from 'react';
import './styles.css';
import { Button } from '@material-ui/core';
import firebase from 'firebase/app'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useGlobalStyles } from '../App/globalStyles';
import ProfileBox from './components/profileBox';
import useCookieTheme from '../App/useCookieTheme';
import { AUTH_ROUTES_CONSULTANT, COMMON_ROUTES } from '../../utils/routes';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { capitalizeFirstLetter } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setConsultantID, setErrorDetails, userState } from '../../api/userSlice';

const ConsultantProfile = (props) => {
  const { code } = props;
  const dispatch = useDispatch()
  const dbUser = useSelector(userState).user
  const userID = useSelector(userState).user?.userID
  const isConsultant = useSelector(userState).user?.consultant
  const integration = useSelector(userState).integration
  const { name, consultantTitle, changePage } = useCookieTheme()
  const { consultant, consultant_code } = props.match?.params ?? {}
  const fromProfile = JSON.parse((new URL(document.location)).searchParams.get("fromProfile") ?? null)
  const reschedulingError = JSON.parse((new URL(document.location)).searchParams.get("reschedulingError") ?? null)
  if (reschedulingError) {
    dispatch(setErrorDetails({
      message: `${consultantTitle ? capitalizeFirstLetter(consultantTitle) : 'Consultant'} is not able to accept this type of booking for moment. Please try again later!`
    }))
  }

  const [consultantData, setConsultantData] = useState(null)
  const [consultantProfilePic, setConsultantProfilePic] = useState(null)

  useEffect(() => {
    dispatch(setConsultantID(consultantData?.userID))

    return () => dispatch(setConsultantID(null))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultantData])

  useEffect(() => {
    if (consultant) {
      return firebase.app().firestore().doc(`/users/${consultant}`).onSnapshot((snapshot) => {
        const data = snapshot.data()

        if (!data?.consultant) {
          changePage({
            pathname: AUTH_ROUTES_CONSULTANT.HOME
          })
        } else {
          setConsultantData(data)
        }
      })
    } else {
      return firebase.app().firestore().collection('/users').where('code', '==', consultant_code ?? code).onSnapshot((snapshot) => {
        const data = snapshot.docs[0]?.data()

        if (!data?.consultant) {
          changePage({
            pathname: AUTH_ROUTES_CONSULTANT.HOME
          })
        } else {
          setConsultantData(data)
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, consultant, consultant_code])

  useEffect(() => {
    if (consultantData && userID && !isConsultant) {
      if (integration && consultantData.integrationThemeID && consultantData.integrationThemeID !== dbUser?.integrationThemeID) {
        firebase.app().firestore().doc(`/users/${userID}`).update({
          integrationThemeID: consultantData.integrationThemeID
        });
      }
      if (consultantData.themeID !== dbUser?.themeID) {
        firebase.app().firestore().doc(`/users/${userID}`).update({
          themeID: consultantData.themeID
        });
      }
    }
  }, [consultantData, integration, isConsultant, userID, dbUser])

  useEffect(() => {
    const asyncProfilePic = async () => {
      firebase.app().storage().ref(`images/${consultantData.userID}.jpg`).getDownloadURL().then(dlURL => {
        setConsultantProfilePic(dlURL)
      }).catch(_ => {
        console.log('no profile pic')
        setConsultantProfilePic(null)
      })
    }

    if (consultantData) {
      asyncProfilePic()
    }
    
    return () => {
      document.title = name
    }
  }, [consultantData, name])

  const makeAppointmentClicked = (isGift) => {
    changePage({
      pathname: COMMON_ROUTES.CONSULTANT_BOOKING.replace(':consultant', consultantData.userID),
      search: isGift ? 'isGift=true' : ''
    })
  }

  useGlobalStyles()

  return (
    <div className="consultantProfile">
      {!consultantData ?
        <div className='consultant_loading'>
          <CircularProgress size={50} />
        </div>
      :
        <>
          {fromProfile &&
            <Button
              className='dismiss_buttton back_to_profile_button'
              onClick={() => changePage({
                pathname: AUTH_ROUTES_CONSULTANT.PROFILE.replace(':id', consultantData.userID)
              })}
              color="primary"
              variant="outlined"
            >
              <KeyboardArrowLeftIcon />
              Back to Profile
            </Button>
          }
          <ProfileBox
            consultantData={consultantData}
            consultantProfilePic={consultantProfilePic}
            makeAppointmentClicked={makeAppointmentClicked}
          />
        </>
      }
    </div>
  )
};

export default ConsultantProfile;
