import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Grid, Button, FormControlLabel, Divider, useMediaQuery, Checkbox } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Country from '../components/country/Country'
import firebase from 'firebase'
import 'firebase/auth';
import useCookieTheme from '../../App/useCookieTheme';
import { FirebaseContext } from '../../Firebase';
import { config, defaultTheme, teamMasters } from '../../../config/config';
import { localeToCurrency, numberFromString } from '../../../utils/utils';
import RoundCheckbox from '../../components/RoundCheckbox';
import SwitchItem from "../../../components/components/SwitchIOS";
import { AUTH_ROUTES_CLIENT, AUTH_ROUTES_CONSULTANT, COMMON_ROUTES, NO_AUTH_ROUTES } from '../../../utils/routes';
import { useGlobalStyles } from '../../App/globalStyles';
import { setErrorDetails, setIsSignUpInBookingProcess, userState } from '../../../api/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { themeState } from '../../../api/themeSlice';
import useBookingHook from '../../ConsultantProfile/useBookingHook';

const SignInComponent = (props) => {
  const {
    setShowLogin, 
    fromConsultantBooking,
    onSubmit,
    contactInfoData,
    joinTeam,
    forConsultant
  } = props
  const { getBooking } = useBookingHook()
  const bookingFlow = getBooking()
  const { logo, bookingLogo, consultantTitle, appointmentName, bookingEnableName, changePage } = useCookieTheme()
  const firebaseSignIn = useContext(FirebaseContext)
  const dispatch = useDispatch()
  const integration = useSelector(userState).integration
  const themeID = useSelector(themeState).theme?.id
  const realThemeID = useSelector(themeState).realThemeID

  const [loading, setLoading] = useState(false)
  const [signUpEnabled, setSignUpEnabled] = useState(false)

  const isClientParam = JSON.parse(localStorage.getItem('isClientParam'))
  const isConsultant = !isClientParam && (config.type !== 'astrologer' || forConsultant) && !fromConsultantBooking
  const consultantForm = useRef(null)
  const [checkedPPM, setCheckedPPM] = useState(false)
  const [checkedBookings, setCheckedBookings] = useState(false)
  const [videoBookingAmounts, setVideoBookingAmounts] = useState(["", "", "", "", "", ""])
  const [audioBookingAmounts, setAudioBookingAmounts] = useState(["", "", "", "", "", ""])
  const [chatBookingAmounts, setChatBookingAmounts] = useState(["", "", "", "", "", ""])
  const [videoPrice, setVideoPrice] = useState("")
  const [audioPrice, setAudioPrice] = useState("")
  const [chatPrice, setChatPrice] = useState("")

  const [username, setUsername] = useState(contactInfoData ? contactInfoData.email : '')
  const [confirmUsername, setConfirmUsername] = useState()
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [signUpName, setSignUpName] = useState(contactInfoData ? contactInfoData.firstName : '')
  const [signUpSurname, setSignUpSurname] = useState(contactInfoData ? contactInfoData.lastName : '')
  const [expertise, setExpertise] = useState("")
  const [selectedCountry, setSelectedCountry] = useState(!isConsultant || config.type === 'astrologer' ? 'us' : null)
  const [selCountry, setSelCountry] = useState(null);
  const [checkedJuridic, setCheckedJuridic] = useState(false)

  const [entityName, setEntityName] = useState("")
  const [entityTIN, setEntityTIN] = useState("")
  const [entityAddress, setEntityAddress] = useState("")

  const heightLessThan1050px = useMediaQuery('(max-height:1049px)');
  const heightLessThan900px = useMediaQuery('(max-height:899px)');
  const heightLessThan720px = useMediaQuery('(max-height:719px)');
  const widthLessThan600px = useMediaQuery('(max-width:599px)');

  const disableSignUp = (!signUpEnabled || loading)
  const minuteIndexes = [15, 30, 45, 60, 75, 90]

  const signUpFirstFormRef = useRef(null)
  const signUpSecondFormRef = useRef(null)

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (confirmPassword !== password) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule('isEmailMatch', (value) => {
      if (confirmUsername !== username) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule('minChars', (value) => {
      if (value.toString().length < 2) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule('isGoodPrice', (value) => {
      if (!value) {
        return true // Will equal 0
      }
      const matchedNoCurrency = value.match(/\d+.?(\d+)?/g) // Remove currency symbols
      const noCurrencySymbols = matchedNoCurrency ? matchedNoCurrency.toString() : '' // Convert to string
      const toCents = numberFromString(noCurrencySymbols)
      const toRet = toCents !== 0 && toCents >= 200
      return toRet
    });
    ValidatorForm.addValidationRule('isGoodPriceInstantCalls', (value) => {
      if (!value) {
        return true // Will equal 0
      }
      const matchedNoCurrency = value.match(/\d+.?(\d+)?/g) // Remove currency symbols
      const noCurrencySymbols = matchedNoCurrency ? matchedNoCurrency.toString() : '' // Convert to string
      const toCents = numberFromString(noCurrencySymbols)
      const toRet = toCents !== 0 && toCents >= 100
      return toRet
    });

    return () => {
      ValidatorForm.removeValidationRule('isPasswordMatch')
      ValidatorForm.removeValidationRule('isEmailMatch')
      ValidatorForm.removeValidationRule('minChars')
      ValidatorForm.removeValidationRule('isGoodPrice')
      ValidatorForm.removeValidationRule('isGoodPriceInstantCalls')
    }
  }, [confirmPassword, confirmUsername, password, username])

  useEffect(() => {
    setSelectedCountry(!isConsultant || config.type === 'astrologer' ? 'us' : null)
  }, [isConsultant])

  useEffect(() => {
    setSignUpEnabled(true);

    if (!(username.length > 1 && password.length > 1)) {
      setSignUpEnabled(false)
      return
    }

    if (!(signUpName.length > 1 && signUpSurname.length > 1)) {
      setSignUpEnabled(false)
      return
    }

    if (isConsultant) {
      if (!selectedCountry) {
        setSignUpEnabled(false)
        return
      }

      if (checkedJuridic) {
        if (entityName.length < 1 || entityTIN.length < 1 || entityAddress.length < 1) {
          setSignUpEnabled(false)
          return
        }
      }

      if (expertise.length === 0) {
        setSignUpEnabled(false)
        return;
      }
    }

    setSignUpEnabled(true)
  }, [isConsultant, username, password, signUpName, signUpSurname, selectedCountry, checkedJuridic, entityName, entityTIN, entityAddress, expertise, setSignUpEnabled])

  const updateConsultantSignUp = useCallback((consultantFormN) => {
    consultantForm.current = consultantFormN
    dispatch(setErrorDetails({
      message: null
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const prettyAudioPrice = numberFromString(audioPrice ?? "")
    const prettyVideoPrice = numberFromString(videoPrice ?? "")
    const prettyChatPrice = numberFromString(chatPrice ?? "")
    const ppmPricing = !checkedPPM ? null : { audioPrice: prettyAudioPrice, videoPrice: prettyVideoPrice, chatPrice: prettyChatPrice }

    const videoAmounts = videoBookingAmounts.map(each => numberFromString(each))
    const audioAmounts = audioBookingAmounts.map(each => numberFromString(each))
    const chatAmounts = chatBookingAmounts.map(each => numberFromString(each))
    const bookingPricing = !checkedBookings ? null : { audio: audioAmounts, video: videoAmounts, chat: chatAmounts }

    updateConsultantSignUp({ ppmPricing: ppmPricing, bookingPricing: bookingPricing })
  }, [checkedPPM, audioPrice, chatPrice, videoPrice, checkedBookings, videoBookingAmounts, audioBookingAmounts, chatBookingAmounts, updateConsultantSignUp])

  const signInDone = (wasSignUp) => {
    if (fromConsultantBooking) {
      dispatch(setIsSignUpInBookingProcess(true))
      onSubmit()
    } else {
      if (bookingFlow && bookingFlow !== "null") {
        if (!wasSignUp) {
          changePage({
            pathname: COMMON_ROUTES.CONSULTANT.replace(':consultant', bookingFlow.perMinuteConsultant),
          })
        } else {
          changePage({
            pathname: AUTH_ROUTES_CLIENT.PAYMENT,
          })
        }
      } else {
        changePage({
          pathname: AUTH_ROUTES_CONSULTANT.HOME,
          search: isClientParam ? '?client=true' : ''
        })
      }
    }
  }

  const signInTapped = async () => {
    setLoading(true)

    const formOK = (await signUpFirstFormRef.current.isFormValid(false)) && (await signUpSecondFormRef.current.isFormValid(false))
    if (!formOK) {
      setLoading(false)
      return
    }

    const toSend = {
      email: username,
      password: password,
      consultant: isConsultant,
      name: signUpName,
      surname: signUpSurname,
      locale: selectedCountry.toLowerCase(),
      consultantCodes: [],
      stripeVersion: "2020-08-27",
      joinTeam: joinTeam
    }
    if (integration) {
      toSend.integrationThemeID = themeID
      toSend.themeID = realThemeID
    } else {
      if (themeID) {
        toSend.themeID = themeID
      } else {
        toSend.themeID = defaultTheme.id
      }
    }
    if (teamMasters === username) {
      toSend.isMaster = true
    } else if (isConsultant && (config.type === 'astrologer' || toSend.themeID !== defaultTheme.id)) {
      toSend.shouldBeInvited = true
    }
    if (checkedJuridic) {
      toSend.legalName = entityName
      toSend.legalTIN = entityTIN
      toSend.legalAddress = entityAddress
    }
    if (isConsultant) {
      toSend.consultancyType = expertise

      let atleastOne = false;
      const consultantFormC = consultantForm.current
      if (consultantFormC) {
        if (consultantFormC.ppmPricing) {
          atleastOne = true
          toSend.videoChargeAmount = consultantFormC.ppmPricing.videoPrice
          toSend.audioChargeAmount = consultantFormC.ppmPricing.audioPrice
          toSend.chatChargeAmount = consultantFormC.ppmPricing.chatPrice

          if (toSend.videoChargeAmount < 1 && toSend.videoChargeAmount !== -100 &&
              toSend.audioChargeAmount < 1 && toSend.audioChargeAmount !== -100 && 
              toSend.chatChargeAmount < 1 && toSend.chatChargeAmount !== -100) {
                dispatch(setErrorDetails({
                  message: `You need to have at least one valid price${config.type === 'yaloop' ? ' or free calls available' : ''}!`
                }))
                setLoading(false)
                return
          }
        }
        if (consultantFormC.bookingPricing) {
          atleastOne = true
          const audioAmounts = consultantFormC.bookingPricing.audio
          const videoAmounts = consultantFormC.bookingPricing.video
          const chatAmounts = consultantFormC.bookingPricing.chat
          toSend.videoBookingAmounts = videoAmounts
          toSend.audioBookingAmounts = audioAmounts
          toSend.chatBookingAmounts = chatAmounts

          if (!toSend.audioBookingAmounts?.filter(amount => amount !== 0).length &&
              !toSend.videoBookingAmounts?.filter(amount => amount !== 0).length &&
              !toSend.chatBookingAmounts?.filter(amount => amount !== 0).length) {
                dispatch(setErrorDetails({
                  message: `You need to have at least one valid price!`
                }))
                setLoading(false)
                return
          }
        }
      }
      if (!atleastOne) {
        if (isConsultant) {
          dispatch(setErrorDetails({
            message: "You need to set either your per-minute or your booking prices to sign up!"
          }))
          setLoading(false)
          return
        }
      }
    }
    console.log(toSend);
    firebase.functions().httpsCallable("createUserFlow")(toSend).then(async _ => {
      if (Boolean(integration)) {
        localStorage.setItem('loggedInWidget', true)
        await firebase.auth().signOut()
      }
      return firebaseSignIn.signIn(username, password)
    }).then(() => {
      if (teamMasters === username) {
        firebase.functions().httpsCallable('createTeam')({ themeID: toSend.themeID })
      }
      signInDone(true)
    }).catch((e) => {
      console.log(e);
      dispatch(setErrorDetails({
        message: "This email address is already in use!"
      }))
      setLoading(false)
    })
  }

  const signUpTapped = () => {
    let hasErrors = false;
    dispatch(setErrorDetails({
      message: null
    }))

    // We got here, so that means all of the above checks are k, time for a few more
    if (password.length < 6) {
      hasErrors = true;
      dispatch(setErrorDetails({
        message: "The password should have minimum 6 characters!"
      }))
    }
    if (password !== confirmPassword) {
      hasErrors = true;
      dispatch(setErrorDetails({
        message: "The passwords do not match!"
      }))
    }
    if (!contactInfoData?.email && username !== confirmUsername) {
      hasErrors = true;
      dispatch(setErrorDetails({
        message: "The emails do not match!"
      }))
    }
    if (signUpName.length > 20) {
      hasErrors = true;
      dispatch(setErrorDetails({
        message: "Invalid first name size. At most 20 chars."
      }))
    }
    if (signUpSurname.length > 20) {
      hasErrors = true;
      dispatch(setErrorDetails({
        message: "Invalid last name size. At most 20 chars."
      }))
    }

    if (isConsultant) {
      if (expertise.length === 0) {
        hasErrors = true;
        dispatch(setErrorDetails({
          message: "Expertise required"
        }))
      }
    }

    if (!hasErrors) {
      signInTapped();
    }
  }

  const signUpConsultantTapped = () => {
    dispatch(setErrorDetails({
      message: null
    }))
    signUpTapped();
  }

  const usernameChange = (event) => {
    setUsername(event.target.value);
  };

  const confirmUsernameChange = (event) => {
    setConfirmUsername(event.target.value);
  };

  const passwordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleCountrySelected = (v) => {
    if (v) {
      setSelectedCountry(v.code);
      setSelCountry(v);
    } else {
      setSelectedCountry(null);
      setSelCountry(null);
    }
  }

  const specialLabel = () => {
    return (
      <div className="special_label">
        By signing up, you agree to our<br />
        <Button color='primary' onClick={() => window.open(NO_AUTH_ROUTES.TERMS_AND_CONDITIONS)}>
          Terms and Conditions
        </Button>
        &nbsp;and&nbsp;
        <Button color='primary' onClick={() => window.open(NO_AUTH_ROUTES.PRIVACY)}>
          Privacy Policy
        </Button>
      </div>
    )
  }

  const inputPropstextFieldStyle = {
    backgroundColor: 'white',
    borderRadius: 10,
    marginTop: 8,
    marginBottom: -7,
    fontSize: 14
  }
  const inputPropsTextField =
    heightLessThan720px ? {
      style: {
        ...inputPropstextFieldStyle,
        marginTop: 2,
        marginBottom: 0
      }
    } : heightLessThan900px ? {
      style: {
        ...inputPropstextFieldStyle,
        marginTop: 2,
        marginBottom: 0
      }
    } : {
      style: inputPropstextFieldStyle
    }

  const inputLabelPropsTextField = heightLessThan720px ? { style: { fontSize: 12 } } : heightLessThan900px ? { style: { fontSize: 14 } } : undefined;

  const curCurrency = selectedCountry ? localeToCurrency(selectedCountry.toLowerCase()) : "";

  useGlobalStyles()

  return (
    <div className='signin_form'>
      {fromConsultantBooking ?
        <div className='signin_text heading'>
          <img 
            alt=""
            src={bookingLogo ?? logo}
          />
          <div className='title'>Create Your Account</div>
          <div>
            You're just a few clicks away! By creating a secure account:<br />
            <ul>
              <li>We'll safely store your {config.type === 'astrologer' && 'birth & '}payment information so you don't have to enter it again;</li>
              <li>You'll be able to bookmark your favorite {consultantTitle}s for future {appointmentName ?? 'appointment'}s;</li>
              <li>And you'll be able to access the downloadable recordings of your {appointmentName ?? 'appointment'}s in one convenient place!</li>
            </ul>
          </div>
        </div>
      :
        <>
          <div className='signin_welcome heading'>Sign Up</div>
          {!fromConsultantBooking &&
            <Grid container justify="center">
              <Button
                className='signin_button signin_button_change_page'
                type="submit"
                disabled={false}
                onClick={() => { setShowLogin(true) }}
                variant="outlined"
                color="primary"
              >Already have an account? Login</Button>
            </Grid>
          }
        </>
      }
      <ValidatorForm
        ref={signUpFirstFormRef}
        className={"signin_form_items"}
        onError={errors => console.log(errors)}
        onSubmit={() => { return }}
      >
        <Grid container spacing={heightLessThan900px ? 1 : 2}>
          <Grid item xs={widthLessThan600px ? 12 : 6}>
            <p>First Name*</p>
            <TextValidator
              validators={['required', 'minChars']}
              variant="outlined"
              size={heightLessThan1050px ? 'small' : 'medium'}
              inputProps={inputPropsTextField}
              errorMessages={['This field is required', 'Min 2 characters']}
              onChange={(e) => { setSignUpName(e.target.value) }}
              value={signUpName}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={widthLessThan600px ? 12 : 6}>
            <p>Last Name*</p>
            <TextValidator
              validators={['required', 'minChars']}
              variant="outlined"
              size={heightLessThan1050px ? 'small' : 'medium'}
              inputProps={inputPropsTextField}
              errorMessages={['This field is required', 'Min 2 characters']}
              onChange={(e) => { setSignUpSurname(e.target.value) }}
              value={signUpSurname} 
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={widthLessThan600px ? 12 : 6}>
            <p>Enter password*</p>
            <TextValidator
              validators={['required']}
              variant="outlined"
              size={heightLessThan1050px ? 'small' : 'medium'}
              inputProps={inputPropsTextField}
              errorMessages={['This field is required']}
              onChange={passwordChange}
              value={password}
              type="password" 
              fullWidth 
              required
            />
          </Grid>
          <Grid item xs={widthLessThan600px ? 12 : 6}>
            <p>Confirm password*</p>
            <TextValidator
              validators={['required', 'isPasswordMatch']}
              variant="outlined"
              size={heightLessThan1050px ? 'small' : 'medium'}
              inputProps={inputPropsTextField}
              errorMessages={['This field is required', 'Password mismatch']}
              onChange={(e) => { setConfirmPassword(e.target.value) }}
              value={confirmPassword}
              type="password"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={widthLessThan600px ? 12 : 6}>
            <p>Email Address*</p>
            <TextValidator
              validators={['required', 'isEmail']}
              errorMessages={['This field is required', 'E-mail is not valid']}
              variant="outlined"
              size={heightLessThan1050px ? 'small' : 'medium'}
              inputProps={inputPropsTextField}
              onChange={usernameChange} 
              value={username}
              type="email"
              fullWidth
              required
              disabled={fromConsultantBooking}
            />
          </Grid>
          {!contactInfoData?.email &&
            <Grid item xs={widthLessThan600px ? 12 : 6}>
              <p>Confirm Email Address*</p>
              <TextValidator
                validators={['required','isEmailMatch']}
                errorMessages={['This field is required', 'Email mismatch']}
                variant="outlined"
                size={heightLessThan1050px ? 'small' : 'medium'}
                inputProps={inputPropsTextField}
                onChange={confirmUsernameChange}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                value={confirmUsername}
                type="email"
                fullWidth
                required
                disabled={fromConsultantBooking}
              />
            </Grid>
          }
          {isConsultant &&
            <>
              <Grid item xs={widthLessThan600px ? 12 : 6}>
                <p>Expertise*</p>
                <TextValidator
                  validators={['required']}
                  variant="outlined"
                  size={heightLessThan1050px ? 'small' : 'medium'}
                  inputProps={inputPropsTextField}
                  errorMessages={['This field is required']}
                  onChange={(e) => { setExpertise(e.target.value) }}
                  value={expertise}
                  fullWidth
                  required
                />
              </Grid>
              {config.type !== 'astrologer' &&
                <Grid item xs={widthLessThan600px ? 12 : 6}>
                  <p>Choose country (currency)*</p>
                  <Country
                    required={true}
                    variant="outlined"
                    selectedCountry={selCountry}
                    countrySelected={handleCountrySelected}
                  />
                </Grid>
              }
              {selectedCountry && selectedCountry !== "US" &&
                <>
                  <Grid item xs={12} className='signin_legal_entity'>
                    <FormControlLabel
                      control={
                        <RoundCheckbox
                          style={{ padding: '0px 9px' }}
                          checked={checkedJuridic}
                          onChange={(e) => {
                            setCheckedJuridic(e.target.checked)
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Are you a legal entity?"
                    />
                  </Grid>
                  {checkedJuridic &&
                    <>
                      <Grid item xs={widthLessThan600px ? 12 : 6}>
                        <p>Entity Name*</p>
                        <TextValidator
                          validators={['required']}
                          variant="outlined"
                          size={heightLessThan1050px ? 'small' : 'medium'}
                          inputProps={inputPropsTextField}
                          errorMessages={['This field is required']}
                          onChange={(e) => { setEntityName(e.target.value) }}
                          value={entityName}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={widthLessThan600px ? 12 : 6}>
                        <p>Entity TIN*</p>
                        <TextValidator
                          validators={['required']}
                          variant="outlined"
                          size={heightLessThan1050px ? 'small' : 'medium'}
                          inputProps={inputPropsTextField}
                          errorMessages={['This field is required']}
                          onChange={(e) => { setEntityTIN(e.target.value) }}
                          value={entityTIN}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={widthLessThan600px ? 12 : 6}>
                        <p>Entity Address*</p>
                        <TextValidator
                          validators={['required']}
                          variant="outlined"
                          size={heightLessThan1050px ? 'small' : 'medium'}
                          inputProps={inputPropsTextField}
                          errorMessages={['This field is required']}
                          onChange={(e) => { setEntityAddress(e.target.value) }}
                          value={entityAddress}
                          fullWidth
                          required
                        />
                      </Grid>
                    </>
                  }
                </>
              }
              <Grid item xs={12}>
                <Divider varinat="middle" />
              </Grid>
            </>
          }
        </Grid>
      </ValidatorForm>

      <ValidatorForm
        ref={signUpSecondFormRef}
        className='signin_options'
        onSubmit={() => { return }}
      >
        <Grid container spacing={heightLessThan900px ? 1 : 2}>
          {isConsultant ?
            <>
              <p className='details_prices'>
                Please enable at least one of the services below
              </p>
              <Grid item xs={12} className='signin_options_item'>
                <FormControlLabel
                  control={<SwitchItem checked={checkedPPM} set={setCheckedPPM} />}
                  label="Enable per-minute instant calls?"
                  labelPlacement="start"
                />
              </Grid>
              {checkedPPM &&
                <>
                  <p className='details_prices'>
                    Choose a per minute price for any desired service type or leave it blank. You need to complete at least one. If you prefer not to offer any per minute instant calls, simply turn off the 'Enable per-minute instant calls?' switch.
                  </p>
                  <Grid item xs={widthLessThan600px ? 12 : 6}>
                    <div className='instant_call_price'>
                      <TextValidator
                        disabled={parseInt(videoPrice) === -1}
                        validators={[`matchRegexp:^.* [0-9]{1,2}(?:\\.[0-9]{1,2})?$`, "isGoodPriceInstantCalls"]}
                        variant="outlined"
                        size={heightLessThan1050px ? 'small' : 'medium'}
                        inputProps={inputPropsTextField}
                        InputLabelProps={inputLabelPropsTextField}
                        errorMessages={['Invalid amount!', 'Price is outside allowed range! Min 1 units.']}
                        onChange={(e) => {
                          const newV = e.target.value.toString().match(/\d+.?(\d+)?/g)
                          setVideoPrice(newV ? newV[0] : '');
                        }}
                        value={parseInt(videoPrice) === -1 ? '' : videoPrice?.length > 0 && curCurrency ? curCurrency + ' ' + videoPrice : videoPrice}
                        id="videoPrice"
                        label="Video Price*" fullWidth
                      />
                      <p>/min</p>
                    </div>
                    {config.type === 'yaloop' &&
                      <>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={parseInt(videoPrice) === -1}
                                    onChange={(e) => {
                                      const newV = e.target.checked ? '-1' : ''
                                      setVideoPrice(newV)
                                    }}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label='FREE'
                        />
                        <Divider />
                      </>
                    }
                  </Grid>
                  <Grid item xs={widthLessThan600px ? 12 : 6}>
                    <div className='instant_call_price'>
                      <TextValidator
                        disabled={parseInt(audioPrice) === -1}
                        validators={[`matchRegexp:^.* [0-9]{1,2}(?:\\.[0-9]{1,2})?$`, "isGoodPriceInstantCalls"]}
                        variant="outlined"
                        size={heightLessThan1050px ? 'small' : 'medium'}
                        inputProps={inputPropsTextField}
                        InputLabelProps={inputLabelPropsTextField}
                        errorMessages={['Invalid amount!', "Price is outside allowed range! Min 1 units."]}
                        onChange={(e) => {
                          const newV = e.target.value.toString().match(/\d+.?(\d+)?/g)
                          setAudioPrice(newV ? newV[0] : '');
                        }}
                        value={parseInt(audioPrice) === -1 ? '' : audioPrice?.length > 0 && curCurrency ? curCurrency + ' ' + audioPrice : audioPrice}
                        id="audioPrice"
                        label="Audio Price*" fullWidth
                      />
                      <p>/min</p>
                    </div>
                    {config.type === 'yaloop' &&
                      <>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={parseInt(audioPrice) === -1}
                                    onChange={(e) => {
                                      const newV = e.target.checked ? '-1' : ''
                                      setAudioPrice(newV)
                                    }}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label='FREE'
                        />
                        <Divider />
                      </>
                    }
                  </Grid>
                  <Grid item xs={widthLessThan600px ? 12 : 6}>
                    <div className='instant_call_price'>
                      <TextValidator
                        disabled={parseInt(chatPrice) === -1}
                        validators={[`matchRegexp:^.* [0-9]{1,2}(?:\\.[0-9]{1,2})?$`, "isGoodPriceInstantCalls"]}
                        variant="outlined"
                        size={heightLessThan1050px ? 'small' : 'medium'}
                        inputProps={inputPropsTextField}
                        InputLabelProps={inputLabelPropsTextField}
                        errorMessages={['Invalid amount!', "Price is outside allowed range! Min 1 units."]}
                        onChange={(e) => {
                          const newV = e.target.value.toString().match(/\d+.?(\d+)?/g)
                          setChatPrice(newV ? newV[0] : '');
                        }}
                        value={parseInt(chatPrice) === -1 ? '' : chatPrice?.length > 0 && curCurrency ? curCurrency + ' ' + chatPrice : chatPrice}
                        id="chatPrice"
                        label="Text Chat Price*" fullWidth
                      />
                      <p>/min</p>
                    </div>
                    {config.type === 'yaloop' &&
                      <>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={parseInt(chatPrice) === -1}
                                    onChange={(e) => {
                                      const newV = e.target.checked ? '-1' : ''
                                      setChatPrice(newV)
                                    }}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label='FREE'
                        />
                        <Divider />
                      </>
                    }
                  </Grid>
                </>
              }

              <Grid item xs={12} className='signin_options_item'>
                <FormControlLabel
                  control={<SwitchItem checked={checkedBookings} set={setCheckedBookings} />}
                  label={`Enable ${bookingEnableName} calls?`}
                  labelPlacement="start"
                />
              </Grid>

              {checkedBookings && (
                <>
                  <p className='details_prices'>
                    Choose a price for any desired session type/duration or leave it blank. You need to complete at least one. If you prefer not to offer any session calls, simply turn off the 'Enable {bookingEnableName} calls?' switch.
                  </p>
                  <Grid item xs={12} className='signin_price_title'>
                    Video {bookingEnableName}s prices
                  </Grid>
                  {minuteIndexes.map((each, index) => {
                    return (
                      <Grid key={index} item xs={widthLessThan600px ? 12 : 6}>
                        <TextValidator
                          key={index}
                          disabled={parseInt(videoBookingAmounts[index]) === -1}
                          validators={[`matchRegexp:^.* [0-9]{1,3}(?:\\.[0-9]{1,2})?$`, "isGoodPrice"]}
                          variant="outlined"
                          size={heightLessThan1050px ? 'small' : 'medium'}
                          inputProps={inputPropsTextField}
                          InputLabelProps={inputLabelPropsTextField}
                          errorMessages={['Invalid amount!', "Price is outside allowed range! Min 2 units."]}
                          onChange={(e) => {
                            const newV = e.target.value.toString().match(/\d+.?(\d+)?/g)
                            const newAmounts = [...videoBookingAmounts]
                            newAmounts[index] = newV ? newV[0] : ''
                            setVideoBookingAmounts(newAmounts)
                          }}
                          value={videoBookingAmounts[index].length > 0 && parseInt(videoBookingAmounts[index]) > 0 ? curCurrency ? curCurrency + ' ' + videoBookingAmounts[index] : videoBookingAmounts[index] : ''}
                          id={`videoPrice${index}`}
                          label={`${each} min session price`} fullWidth
                        />
                        {config.type === 'yaloop' &&
                          <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={parseInt(videoBookingAmounts[index]) === -1}
                                        onChange={(e) => {
                                          const newV = e.target.checked ? '-1' : '0'
                                          const newAmounts = [...videoBookingAmounts]
                                          newAmounts[index] = newV
                                          setVideoBookingAmounts(newAmounts)
                                        }}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label='FREE'
                            />
                            <Divider />
                          </>
                        }
                      </Grid>
                    )
                  })}
                  <Grid item xs={12} className='signin_price_title'>
                    Audio {bookingEnableName}s prices
                  </Grid>
                  {minuteIndexes.map((each, index) => {
                    return (
                      <Grid key={index} item xs={widthLessThan600px ? 12 : 6}>
                        <TextValidator
                          key={index}
                          disabled={parseInt(audioBookingAmounts[index]) === -1}
                          validators={[`matchRegexp:^.* [0-9]{1,3}(?:\\.[0-9]{1,2})?$`, "isGoodPrice"]}
                          variant="outlined"
                          size={heightLessThan1050px ? 'small' : 'medium'}
                          inputProps={inputPropsTextField}
                          InputLabelProps={inputLabelPropsTextField}
                          errorMessages={['Invalid amount!', "Price is outside allowed range! Min 2 units."]}
                          onChange={(e) => {
                            const newV = e.target.value.toString().match(/\d+.?(\d+)?/g)
                            const newAmounts = [...audioBookingAmounts]
                            newAmounts[index] = newV ? newV[0] : ''
                            setAudioBookingAmounts(newAmounts)
                          }}
                          value={audioBookingAmounts[index].length > 0 && parseInt(audioBookingAmounts[index]) > 0 ? curCurrency ? curCurrency + ' ' + audioBookingAmounts[index] : audioBookingAmounts[index] : ''}
                          id={`audioPrice${index}`}
                          label={`${each} min session price`} fullWidth
                        />
                        {config.type === 'yaloop' &&
                          <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={parseInt(audioBookingAmounts[index]) === -1}
                                        onChange={(e) => {
                                          const newV = e.target.checked ? '-1' : '0'
                                          const newAmounts = [...audioBookingAmounts]
                                          newAmounts[index] = newV
                                          setAudioBookingAmounts(newAmounts)
                                        }}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label='FREE'
                            />
                            <Divider />
                          </>
                        }
                      </Grid>
                    )
                  })}
                  <Grid item xs={12} className='signin_price_title'>
                    Text chat {bookingEnableName}s prices
                  </Grid>
                  {minuteIndexes.map((each, index) => {
                    return (
                      <Grid key={index} item xs={widthLessThan600px ? 12 : 6}>
                        <TextValidator
                          key={index}
                          disabled={parseInt(chatBookingAmounts[index]) === -1}
                          validators={[`matchRegexp:^.* [0-9]{1,3}(?:\\.[0-9]{1,2})?$`, "isGoodPrice"]}
                          variant="outlined"
                          size={heightLessThan1050px ? 'small' : 'medium'}
                          inputProps={inputPropsTextField}
                          InputLabelProps={inputLabelPropsTextField}
                          errorMessages={['Invalid amount!', "Price is outside allowed range! Min 2 units."]}
                          onChange={(e) => {
                            const newV = e.target.value.toString().match(/\d+.?(\d+)?/g)
                            const newAmounts = [...chatBookingAmounts]
                            newAmounts[index] = newV ? newV[0] : ''
                            setChatBookingAmounts(newAmounts)
                          }}
                          value={chatBookingAmounts[index].length > 0 && parseInt(chatBookingAmounts[index]) > 0 ? curCurrency ? curCurrency + ' ' + chatBookingAmounts[index] : chatBookingAmounts[index] : ''}
                          id={`chatPrice${index}`}
                          label={`${each} min session price`} fullWidth
                        />
                        {config.type === 'yaloop' &&
                          <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={parseInt(chatBookingAmounts[index]) === -1}
                                        onChange={(e) => {
                                          const newV = e.target.checked ? '-1' : '0'
                                          const newAmounts = [...chatBookingAmounts]
                                          newAmounts[index] = newV
                                          setChatBookingAmounts(newAmounts)
                                        }}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label='FREE'
                            />
                            <Divider />
                          </>
                        }
                      </Grid>
                    )
                  })}
                </>
              )}
              <Grid item xs={12} className='signin_options_submit'>
                <Button
                  className='signin_button'
                  type="submit"
                  disabled={disableSignUp}
                  onClick={signUpConsultantTapped}
                  variant="contained"
                  color="primary"
                >{loading ? <CircularProgress size={10} /> : 'Sign Up'}</Button>
              </Grid>
            </>
            :
            <Grid item xs={12} className={`signin_options_submit signin_options_submit_bottom ${fromConsultantBooking ? 'oswald_font' : ''}`}>
              <Button
                className='signin_button'
                type="submit"
                disabled={disableSignUp}
                onClick={signUpTapped}
                variant="contained"
                color="primary"
              >{loading ? <CircularProgress size={10} /> : fromConsultantBooking ? 'CREATE MY ACCOUNT' : `Sign Up`}</Button>
            </Grid>
          }
        </Grid>
      </ValidatorForm>
      <Grid container justify="center" style={{
        maxWidth: "70%",
        margin: 'auto',
        marginTop: `${heightLessThan1050px ? '4px' : '10px'}`,
        fontSize: `${heightLessThan720px ? '7px' : heightLessThan900px ? '10px' : heightLessThan1050px ? '12px' : '14px'}`,
        textAlign: 'center'
      }}>
        {specialLabel("termsandconditions", "privacy")}
      </Grid>
    </div>
  )
};

export default SignInComponent;
